import React, { useContext } from 'react';
import * as S from './QuantityWithButtons.styles';
import FunnelContext from '../../../context/FunnelContext';

const SelectContainer = ({ items, useSelectComponent }) => {
  const {
    currentCategory,
    setTubIndex,
    currentTubIndex,
    setCurrentTubIndex,
    setCurrentProduct
  } = useContext(FunnelContext);

  const productPrefix =
    currentCategory === `onetime` ? `onetime` : `subscription`;

  const handleChangePlus = () => {
    if (items.length - 1 > currentTubIndex) {
      setCurrentTubIndex(currentTubIndex + 1);
      setTubIndex(currentTubIndex + 1);
      setCurrentProduct(`${productPrefix}_${currentTubIndex + 1}`);
    } else {
      return currentTubIndex;
    }
  };

  const handleChangeMinus = () => {
    if (currentTubIndex > 0) {
      setCurrentTubIndex(currentTubIndex - 1);
      setTubIndex(currentTubIndex - 1);
      setCurrentProduct(`${productPrefix}_${currentTubIndex - 1}`);
    } else {
      return currentTubIndex;
    }
  };

  if (useSelectComponent) {
    return (
      <div style={{ display: 'flex' }}>
        <S.Button
          onClick={handleChangeMinus}
          className={`${currentTubIndex > 0 ? `` : `not-active`}`}
        >
          <S.Minus></S.Minus>
        </S.Button>
        <S.TubCount>{currentTubIndex + 1}</S.TubCount>
        <S.Button
          onClick={handleChangePlus}
          className={`${
            items.length - 1 > currentTubIndex ? `` : `not-active`
          }`}
        >
          <S.Plus></S.Plus>
        </S.Button>
      </div>
    );
  } else {
    return 3;
  }
};

const QuantityWithButtons = ({ items, label, useSelectComponent }) => {
  return (
    <S.Container>
      <S.Label htmlFor="quantity-select-input">{label}</S.Label>
      <SelectContainer useSelectComponent={useSelectComponent} items={items} />
    </S.Container>
  );
};

export default QuantityWithButtons;
